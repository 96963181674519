import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  Cards,
  GoBack,
  Grid,
  HelpfulResources,
  PageHeader,
  StillNeedHelp,
  Typography as T,
} from '../../../components';
import GeneralPaddingSection from '../../../components/Layout/GeneralPaddingSection';
import { common } from '../../../constants';
import { usePublicOrg } from '../../../context/public-org';
import * as S from './style';
const { Row, Col } = Grid;
const { SingleTip } = Cards;

const BudgetingPage = () => {
  const { t } = useTranslation();
  const { publicOrg, setPageTitle } = usePublicOrg();
  const stillNeedHelp = publicOrg?.resources?.find(
    (resource) => resource.key === 'STILL_NEED_HELP'
  );

  useEffect(() => {
    setPageTitle('Budgeting');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Helmet>
        <title>{'Cost of Living Support: Budgeting'}</title>
        <meta
          name="description"
          content={
            'Doing a budget to work out how much money you have coming in and going out is a great place to start. There are many budgeting tools available to help you do this, however a debt advisor or a money advisor will be able to help you if you find it difficult to do on your own.'
          }
        />
        <meta name="keywords" content={'Budgeting'} />
      </Helmet>
      <S.Container>
        <PageHeader
          title={t(
            'common.section.budgeting.title',
            common.section.budgeting.title
          )}
          textColor="secondaryMain"
          bgColor="neutralLight"
          borderColor="white"
        />
        <GeneralPaddingSection>
          <S.Content>
            <S.Topics>
              <Row mb="6" inner>
                <Col w={[4, 12, 12]}>
                  <T.P color="neutralDark">
                    {t(
                      'common.section.budgeting.description',
                      common.section.budgeting.description
                    )}
                  </T.P>
                </Col>
              </Row>
              <Row mb="4" inner>
                <Col w={[4, 12, 12]}>
                  <T.H2 color="neutralMain">
                    {t(
                      'common.section.budgeting.tipTitle',
                      common.section.budgeting.tipTitle
                    )}
                  </T.H2>
                </Col>
              </Row>
              {/* first tip */}
              <Row mb="3" inner>
                <Col w={[4, 12, 12]}>
                  <SingleTip
                    bgColor="secondaryMain"
                    borderColor="white"
                    iconColor="white"
                    tip={
                      <div>
                        <T.H3 color="white">
                          {t(
                            'common.section.budgeting.tip',
                            common.section.budgeting.tip
                          )}
                        </T.H3>
                        <S.TipList ml="5" color="white">
                          <li>
                            <T.H3 color="white">
                              {t(
                                'common.section.budgeting.tip1P1',
                                common.section.budgeting.tip1P1
                              )}
                            </T.H3>
                          </li>
                          <li>
                            <T.H3 color="white">
                              {t(
                                'common.section.budgeting.tip1P2',
                                common.section.budgeting.tip1P2
                              )}
                            </T.H3>
                          </li>
                          <li>
                            <T.H3 color="white">
                              {t(
                                'common.section.budgeting.tip1P3',
                                common.section.budgeting.tip1P2
                              )}
                            </T.H3>
                          </li>
                        </S.TipList>
                      </div>
                    }
                  />
                </Col>
              </Row>

              {/* second tip */}
              <Row mb="6" inner>
                <Col w={[4, 12, 12]}>
                  <SingleTip
                    bgColor="secondaryLight"
                    borderColor="secondaryMain"
                    iconColor="secondaryMain"
                    tip={
                      <div>
                        <T.H3 color="secondaryMain">
                          {t(
                            'common.section.budgeting.tip',
                            common.section.budgeting.tip
                          )}
                        </T.H3>
                        <S.TipList ml="5" color="secondaryMain">
                          <li>
                            <T.H3 color="secondaryMain">
                              {t(
                                'common.section.budgeting.tip2P1',
                                common.section.budgeting.tip2P1
                              )}
                            </T.H3>
                          </li>
                          <li>
                            <T.H3 color="secondaryMain">
                              {t(
                                'common.section.budgeting.tip2P2',
                                common.section.budgeting.tip2P2
                              )}
                            </T.H3>
                          </li>
                        </S.TipList>
                      </div>
                    }
                  />
                </Col>
              </Row>
              <Row mb="6" inner>
                <Col w={[4, 12, 12]}>
                  <HelpfulResources
                    resources={publicOrg?.resources?.filter(
                      (r) => r.category === 'BUDGET'
                    )}
                  />
                </Col>
              </Row>
            </S.Topics>
            <S.HelpSection>
              <StillNeedHelp
                name={stillNeedHelp?.label}
                phoneNumber={stillNeedHelp?.value}
              />
              <GoBack mt={4} />
            </S.HelpSection>
          </S.Content>
        </GeneralPaddingSection>
      </S.Container>
    </>
  );
};

export default BudgetingPage;
