import { message } from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { Sections } from '../../../api-calls';
import { Grid, Typography as T, TextWithIcon } from '../../../components';
import GeneralPaddingSection from '../../../components/Layout/GeneralPaddingSection';
import PageHeader from '../../../components/PageHeader';
import { common, navRoutes } from '../../../constants';
import { usePublicOrg } from '../../../context/public-org';
import { useLanguage } from '../../../helpers';

import * as S from './style';
const { Col, Row } = Grid;

const SubSections = () => {
  const { i18n, t } = useTranslation();
  const { lng } = useLanguage();
  const [data, setData] = useState({});
  const { publicOrg, setPageTitle } = usePublicOrg();

  const { slug } = useParams();

  useEffect(() => {
    let mounted = true;
    async function fetchData() {
      const hideMessage = message.loading('Loading...');
      const { data: _data, error } = await Sections.getSubSections({
        slug,
        forPublic: true,
        lng,
      });
      if (mounted) {
        if (error) {
          message.error('Something went wrong, please try again later');
        } else {
          setData(_data);
          setPageTitle(_data.title);
        }
        hideMessage();
      }
    }

    fetchData();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, lng]);

  const colorArr = [
    'primaryMain',
    'secondaryMain',
    'tertiaryMain',
    'neutralMain',
  ];

  i18n.addResourceBundle(lng, 'subsectionNS', {
    data,
  });

  const _data = t('data', { ns: 'subsectionNS', returnObjects: true });

  return (
    <>
      <Helmet>
        <title>{`Cost of Living Support: ${_data.title || ''}`}</title>
        <meta
          name="description"
          content={`Cost of Living Support | ${t(
            'common.section.subSection.description',
            common.section.subSection.description
          )}`}
        />
        <meta name="keywords" content={_data.title} />
      </Helmet>
      <S.Container>
        <PageHeader title={_data.title} />
        <GeneralPaddingSection>
          <Row jc="center" mb="4">
            <Col w={[4, 8, 6]}>
              <T.H2>
                {t(
                  'common.section.subSection.description',
                  common.section.subSection.description
                )}
              </T.H2>
            </Col>
          </Row>
          {_data?.childrenSections?.map((item, index) => (
            <Row jc="center" mb="2">
              <Col w={[4, 8, 6]}>
                <S.ButtonWrapper
                  to={generatePath(navRoutes.PUBLIC_ORG.SECTION, {
                    slug: item.slug,
                    uniqueSlug: publicOrg?.uniqueSlug,
                  })}
                >
                  <TextWithIcon
                    size="large"
                    bgColor="neutralSurface"
                    text={item.title}
                    jc="center"
                    jcT="flex-start"
                    mr="6px"
                    isText
                    iconProps={{
                      color: colorArr[index % colorArr.length],
                      icon: 'forwardArrow',
                    }}
                  />
                </S.ButtonWrapper>
              </Col>
            </Row>
          ))}
        </GeneralPaddingSection>
      </S.Container>
    </>
  );
};

export default SubSections;
