import { message } from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Sections } from '../../../api-calls';
import { TopicCard } from '../../../components/Cards';
import GoBack from '../../../components/GoBack';
import GeneralPaddingSection from '../../../components/Layout/GeneralPaddingSection';
import PageHeader from '../../../components/PageHeader';
import StillNeedHelp from '../../../components/StillNeedHelp';
import { contentColors, navRoutes } from '../../../constants';
import { usePublicOrg } from '../../../context/public-org';
import { useLanguage } from '../../../helpers';
import * as S from './style';
import useTopics from './useTopics';

const Section = () => {
  const { i18n, t } = useTranslation();
  const { lng } = useLanguage();
  const { publicOrg, setPageTitle } = usePublicOrg();

  const { slug } = useParams();
  const navigate = useNavigate();

  const topicNS = 'topicNS' + slug;

  const [sectionData, setSectionData] = useState({});
  const { topics, toggleMark } = useTopics(slug, lng, publicOrg?.resources);

  useEffect(() => {
    const fetchSectionData = async () => {
      const { data, error } = await Sections.getSectionBySlug({
        slug,
        forPublic: true,
        lng,
      });
      if (error) {
        if (error.statusCode === 404) {
          return navigate(navRoutes.GENERAL.NOT_FOUND);
        }
        message.error('Something went wrong, please try again later');
      } else {
        setSectionData(data);
        setPageTitle(data.title);
      }
    };
    fetchSectionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, navigate, lng]);

  i18n.addResourceBundle(lng, topicNS, {
    topics,
  });

  i18n.addResourceBundle(lng, 'sectionDataNS', {
    sectionData,
  });

  const _topics = t('topics', { ns: topicNS, returnObjects: true });

  const { title, parentSectionTitle } = t('sectionData', {
    ns: 'sectionDataNS',
    returnObjects: true,
  });

  const pageTitle = parentSectionTitle
    ? `${parentSectionTitle.replace(/\*\*/g, '')} **${title}**`
    : title;

  const stillNeedHelp = publicOrg?.resources?.find(
    (resource) => resource.key === 'STILL_NEED_HELP'
  );

  const colors =
    contentColors[sectionData?.id] || contentColors[sectionData?.themeKey || 1];

  return (
    <>
      <Helmet>
        <title>{`Cost of Living Support: ${sectionData?.title || ''}`}</title>
        <meta
          name="description"
          content={`Cost of Living Support | ${sectionData.description}`}
        />
        <meta name="keywords" content={sectionData.title} />
      </Helmet>
      <S.Container>
        <PageHeader
          title={pageTitle}
          bgColor={colors.bg}
          icon={colors.icon}
          iconColor={colors.iconColor}
          borderColor={colors.borderColor}
          textColor={colors.textColor}
        />
        <GeneralPaddingSection>
          <S.Content>
            <S.Topics>
              {_topics.map(({ id, marked, content }, i) => (
                <TopicCard
                  topicIndex={i}
                  key={id}
                  title={content.title}
                  description={content.content}
                  tips={[content.tip1, content.tip2]}
                  toggleMark={() => toggleMark(id)}
                  marked={marked}
                  resources={content.resources}
                />
              ))}
            </S.Topics>
            <S.HelpSection>
              <StillNeedHelp
                name={stillNeedHelp?.label}
                phoneNumber={stillNeedHelp?.value}
              />
              <GoBack mt={4} />
            </S.HelpSection>
          </S.Content>
        </GeneralPaddingSection>
      </S.Container>
    </>
  );
};

export default Section;
